import { processOneTimePaymentBegan, processSubscriptionBegan, uiNotificationSent, routeChangeBegan } from '../actionUtils';
import { General, Payment } from '../../../common/constants';
import { getApi } from '../../../common/axiosExtensions';
import { isEmpty, getEnvironmentVariables } from '../../../common/utils';
import config from "../../../config.json";

//1. One Time Payment Flow
//1.1 (Frontend) Create Payment Method
//1.2 Process One Time Payment
//1.2.1 (Backend) Create/Update Payment Intent
//1.2.2 (Backend) Receive the secret
//1.2.3 (Backend) Attach Payment Method
//1.3 (Frontend) Confirm Card Payment
//1.4 To Be Done: Authenticate 3D Secure

export const oneTimePaymentOrchestrator = ({ dispatch, getState }) => (next) => async (action) => {
    const showErrorToUser = (error) => {
        var errorPayload = {
            statusCode: error.response?.status || '',
            message: error.message,
            errorCode: error.response?.data?.code || '',
            userFriendlyMessage: error.response?.data?.description || General.ErrorMessage
        }

        if (paymentPayload.onError) {
            dispatch({
                type: paymentPayload.onError,
                payload: {
                    status: Payment.PaymentStatus.Failed
                }
            });
        }

        dispatch(uiNotificationSent(errorPayload));
    }

    if (action.type !== processOneTimePaymentBegan.type) {
        return next(action);
    }

    let paymentPayload = action.payload;

    if (paymentPayload.onStart) {
        dispatch({
            type: paymentPayload.onStart,
            payload: {
                status: Payment.PaymentStatus.RequiresAction
            }
        });
    }

    let billingDetails = {
        address: paymentPayload.data.billingDetails.address,
        email: paymentPayload.data.billingDetails.email,
        phone: paymentPayload.data.billingDetails.phone,
        name: paymentPayload.data.billingDetails.name
    };

    const createPaymentMethodPayload = await paymentPayload.data.stripe.createPaymentMethod({
        type: paymentPayload.data.type,
        card: paymentPayload.data.card,
        billing_details: billingDetails
    });

    if (createPaymentMethodPayload.error) {
        showErrorToUser(createPaymentMethodPayload.error);
        return next(action);
    }

    try {
        //process one time payment
        const processOneTimePaymentResponse = await getApi(
            getEnvironmentVariables().REACT_APP_SUBSCRIPTION_API_URL,
            paymentPayload.data.accessToken,
            paymentPayload.data.refreshToken,
            dispatch)
            .request({
                url: config.payments.processOneTimePaymentUrl,
                headers: {},
                method: 'POST',
                data: {
                    type: paymentPayload.data.type,
                    email: paymentPayload.data.email,
                    customerId: paymentPayload.data.customerId,
                    priceId: paymentPayload.data.priceId,
                    paymentMethodId: createPaymentMethodPayload.paymentMethod.id,
                    userId: paymentPayload.data.userId,
                    firstName: paymentPayload.data.firstName,
                    lastName: paymentPayload.data.lastName,
                    phone: paymentPayload.data.phone
                }
            });

        let member = processOneTimePaymentResponse?.data?.member;
        let nextAction = processOneTimePaymentResponse?.data?.nextAction;

        if (!isEmpty(nextAction) && nextAction.requires3DSecure) {
            dispatch({
                type: paymentPayload.onThreeDAuthenticationStart,
                payload: processOneTimePaymentResponse?.data
            });

            dispatch(routeChangeBegan({
                'intent': 'payment-authorisation',
            }));
        } else if (!isEmpty(member)) {
            if (paymentPayload.onSuccess) {
                dispatch({
                    type: paymentPayload.onSuccess,
                    payload: processOneTimePaymentResponse?.data
                });

                dispatch(routeChangeBegan({
                    'intent': 'payment-confirmation',
                }));
            }
        }
    }
    catch (error) {
        showErrorToUser(error);
    }

    next(action);
};

export const recurringPaymentOrchestrator = ({ dispatch, getState }) => (next) => async (action) => {
    const showErrorToUser = (error) => {
        var errorPayload = {
            statusCode: error.response?.status || '',
            message: error.message,
            errorCode: error.response?.data?.code || '',
            userFriendlyMessage: error.response?.data?.description || General.ErrorMessage
        }

        if (subscriptionPayload.onError) {
            dispatch({
                type: subscriptionPayload.onError,
                payload: {
                    status: Payment.SubscriptionStatus.Failed
                }
            });
        }

        dispatch(uiNotificationSent(errorPayload));
    }
    if (action.type !== processSubscriptionBegan.type) {
        return next(action);
    }

    let subscriptionPayload = action.payload;

    if (subscriptionPayload.onStart) {
        dispatch({
            type: subscriptionPayload.onStart,
            payload: {
                status: Payment.SubscriptionStatus.RequiresAction
            }
        });
    }

    let billingDetails = {
        address: subscriptionPayload.data.billingDetails.address,
        email: subscriptionPayload.data.billingDetails.email,
        phone: subscriptionPayload.data.billingDetails.phone,
        name: subscriptionPayload.data.billingDetails.name
    };

    const createPaymentMethodPayload = await subscriptionPayload.data.stripe.createPaymentMethod({
        type: subscriptionPayload.data.type,
        card: subscriptionPayload.data.card,
        billing_details: billingDetails
    });

    if (createPaymentMethodPayload.error) {
        showErrorToUser(createPaymentMethodPayload.error);
        return next(action);
    }

    try {
        //process recurring payment
        const processSubscriptionResponse = await getApi(
            getEnvironmentVariables().REACT_APP_SUBSCRIPTION_API_URL,
            subscriptionPayload.data.accessToken,
            subscriptionPayload.data.refreshToken,
            dispatch)
            .request({
                url: config.subscriptions.processRecurringPaymentUrl,
                headers: {},
                method: 'POST',
                data: {
                    type: subscriptionPayload.data.type,
                    email: subscriptionPayload.data.email,
                    priceId: subscriptionPayload.data.priceId,
                    paymentMethodId: createPaymentMethodPayload.paymentMethod.id,
                    userId: subscriptionPayload.data.userId,
                    firstName: subscriptionPayload.data.firstName,
                    lastName: subscriptionPayload.data.lastName,
                    phone: subscriptionPayload.data.phone
                }
            });

        let member = processSubscriptionResponse?.data?.member;
        let nextAction = processSubscriptionResponse?.data?.nextAction;

        if (!isEmpty(nextAction) && nextAction.requires3DSecure) {
            dispatch({
                type: subscriptionPayload.onThreeDAuthenticationStart,
                payload: processSubscriptionResponse?.data
            });

            dispatch(routeChangeBegan({
                'intent': 'payment-authorisation',
            }));
        } else if (!isEmpty(member)) {
            if (subscriptionPayload.onSuccess) {
                dispatch({
                    type: subscriptionPayload.onSuccess,
                    payload: processSubscriptionResponse?.data
                });

                dispatch(routeChangeBegan({
                    'intent': 'payment-confirmation',
                }));
            }
        }
    }
    catch (error) {
        showErrorToUser(error);
    }

    next(action);
};