import React, { Component, lazy } from 'react';
import { Switch, Route } from "react-router-dom";
import { Routes } from './common/constants';

//Pages
const Policies = lazy(() => import("./screens/Content/Policies/Policies"));
const Terms = lazy(() => import("./screens/Content/Policies/Terms"));
const ContactUs = lazy(() => import("./screens/Others/ContactUs"));
const WorkWithMe = lazy(() => import("./screens/Others/WorkWithMe/Index"));
const DemoRequest = lazy(() => import("./screens/Products/DemoRequest"));
const NotFound = lazy(() => import("./screens/Others/NotFound"));
const Roadmap = lazy(() => import("./screens/Products/Roadmap"));

//Subscription & Payment
const PaymentAuthorisation = lazy(() => import("./screens/Registration/PaymentAuthorisation"));
const Confirmation = lazy(() => import("./screens/Registration/Confirmation"));

//User
const AuthContainer = lazy(() => import("./screens/User/AuthContainer"));
const ExternalAuthenticationRedirect = lazy(() => import("./screens/User/ExternalAuthenticationRedirect"));
const CommsActivations = lazy(() => import("./screens/User/CommsActivations"));
const ProtectedRoute = lazy(() => import("./components/common/ProtectedRoute"));
const PasswordResetLink = lazy(() => import("./screens/User/PasswordResetLink"));
const ChangePassword = lazy(() => import("./screens/User/ChangePassword"));
const AccountInformation = lazy(() => import("./screens/Registration/AccountInformation"));
const Onboarding = lazy(() => import("./screens/Registration/Onboarding"));
const SwitchPlan = lazy(() => import("./screens/User/SwitchPlan"));

//Content
const Conversion = lazy(() => import("./screens/Content/Template/Conversion"))
const GatedResource = lazy(() => import("./screens/Resources/Index"));
const HowRekenixWorks = lazy(() => import("./screens/Content/Blog/Posts/How-Rekenix-Works"));
const HowPellerexWorks = lazy(() => import("./screens/Content/Blog/Posts/Pellerex-Overview"));
const BlogList = lazy(() => import("./screens/Content/Blog/BlogList"));
const UrlRewrite = lazy(() => import("./screens/Content/Blog/Posts/API/UrlRewrite"));
const Authentication = lazy(() => import("./screens/Content/Blog/Posts/Auth/Authentication"));
const ApiSetup = lazy(() => import("./screens/Content/Blog/Posts/API/ApiSetup"));
const ApiVersioning = lazy(() => import("./screens/Content/Blog/Posts/API/ApiVersioning"));
const ApiConfigurationManagement = lazy(() => import("./screens/Content/Blog/Posts/API/ApiConfigurationManagement"));
const ApiSecretManagement = lazy(() => import("./screens/Content/Blog/Posts/API/ApiSecretManagement"));
const ApiMonitoring = lazy(() => import("./screens/Content/Blog/Posts/API/ApiMonitoring"));
const ApiDatabase = lazy(() => import("./screens/Content/Blog/Posts/API/ApiDatabase"));
const ApiDocumentation = lazy(() => import("./screens/Content/Blog/Posts/API/ApiDocumentation"));
const ApiCors = lazy(() => import("./screens/Content/Blog/Posts/API/ApiCors"));
const ApiRequestValidation = lazy(() => import("./screens/Content/Blog/Posts/API/ApiRequestValidation"));
const ApiExceptionHandling = lazy(() => import("./screens/Content/Blog/Posts/API/ApiExceptionHandling"));
const SpaSetup = lazy(() => import("./screens/Content/Blog/Posts/Web/SpaSetup"));
const SpaStyling = lazy(() => import("./screens/Content/Blog/Posts/Web/SpaStyling"));
const SpaGlobalNavigation = lazy(() => import("./screens/Content/Blog/Posts/Web/SpaGlobalNavigation"));
const SpaGeneralLayout = lazy(() => import("./screens/Content/Blog/Posts/Web/SpaGeneralLayout"));
const SpaForms = lazy(() => import("./screens/Content/Blog/Posts/Web/SpaForms"));
const SpaRouting = lazy(() => import("./screens/Content/Blog/Posts/Web/SpaRouting"));
const SpaConfiguration = lazy(() => import("./screens/Content/Blog/Posts/Web/SpaConfiguration"));
const SpaApiCall = lazy(() => import("./screens/Content/Blog/Posts/Web/SpaApiCall"));
const SpaMonitoring = lazy(() => import("./screens/Content/Blog/Posts/Web/SpaMonitoring"));
const SpaNotification = lazy(() => import("./screens/Content/Blog/Posts/Web/SpaNotification"));
const SpaReactRedux = lazy(() => import("./screens/Content/Blog/Posts/Web/SpaReactRedux"));
const SpaGoogleMaps = lazy(() => import("./screens/Content/Blog/Posts/Web/SpaGoogleMap"));
const SpaSeo = lazy(() => import("./screens/Content/Blog/Posts/Web/SpaSeo"));
const SpaAzureDeploy = lazy(() => import("./screens/Content/Blog/Posts/Web/SpaAzureDeploy"));
const SpaAspNetShellConfigurations = lazy(() => import("./screens/Content/Blog/Posts/Web/SpaAspNetShellConfigurations"));
const AuthIdentityDbSetup = lazy(() => import("./screens/Content/Blog/Posts/Auth/AuthIdentitySetup"));
const EmailSender = lazy(() => import("./screens/Content/Blog/Posts/Auth/EmailSender"));
const SignUp = lazy(() => import("./screens/Content/Blog/Posts/Auth/SignUp"));
const ApiCall = lazy(() => import("./screens/Content/Blog/Posts/API/ApiCall"));
const AuthStatusCheck = lazy(() => import("./screens/Content/Blog/Posts/Auth/CheckUserStatus"));
const AuthChangePassword = lazy(() => import("./screens/Content/Blog/Posts/Auth/ChangePassword"));
const SignIn = lazy(() => import("./screens/Content/Blog/Posts/Auth/SignIn"));
const TokenValidation = lazy(() => import("./screens/Content/Blog/Posts/Auth/TokenValidation"));
const TokenRefresh = lazy(() => import("./screens/Content/Blog/Posts/Auth/TokenRefresh"));
const PaymentBlog = lazy(() => import("./screens/Content/Blog/Posts/StripePayment/StripePayment"));
const StripeCaptureCardDetails = lazy(() => import("./screens/Content/Blog/Posts/StripePayment/DetailsCapture"));
const StripeOneTimePayment = lazy(() => import("./screens/Content/Blog/Posts/StripePayment/OneTimePayment"));
const StripeSubscriptions = lazy(() => import("./screens/Content/Blog/Posts/StripePayment/Subscription"));
const StructuredLogging = lazy(() => import("./screens/Content/Blog/Posts/API/ApiStructuredLogging"));
const GoogleExternalAuthentication = lazy(() => import("./screens/Content/Blog/Posts/Auth/GoogleExternalAuthentication"));
const MicrosoftExternalAuthentication = lazy(() => import("./screens/Content/Blog/Posts/Auth/MicrosoftExternalAuthentication"));
const TwitterExternalAuthentication = lazy(() => import("./screens/Content/Blog/Posts/Auth/TwitterExternalAuthentication"));
const SoftwareTransformationRoadmap = lazy(() => import("./screens/Content/Blog/Posts/Strategy/EngineeringRoadmap"));
const LowCodePlatforms = lazy(() => import("./screens/Content/Blog/Posts/Strategy/LowCodeComparison"));
const AspNetApiContainerisation = lazy(() => import("./screens/Content/Blog/Posts/Infrastructure/ApiContainerisation"));
const AspNetAppContainerisation = lazy(() => import("./screens/Content/Blog/Posts/Infrastructure/AppContainerisation"));
const BoilerplateSolutions = lazy(() => import('./screens/Content/Blog/Posts/Strategy/BoilerplateSolutions'));
const DockerCompose = lazy(() => import('./screens/Content/Blog/Posts/Infrastructure/DockerCompose'));
const PellerexOnMinikube = lazy(() => import('./screens/Content/Blog/Posts/Infrastructure/PellerexOnMinikube'));
const PellerexOnKubernetes = lazy(() => import('./screens/Content/Blog/Posts/Infrastructure/PellerexOnProdKube'));
const EventDrivenArchitecture = lazy(() => import('./screens/Content/Blog/Posts/Messaging/EventDrivenArchitecture'));
const CommandsRepo = lazy(() => import('./screens/Content/Blog/Posts/Common/Commands'));
const PerformanceUplift = lazy(() => import('./screens/Content/Blog/Posts/Infrastructure/PerformanceUplift'));
const RealtimeMessaging = lazy(() => import('./screens/Content/Blog/Posts/Messaging/RealtimeChat'));
const AIMarketplace = lazy(() => import('./screens/Content/Blog/Posts/AI/AIMarketplace'));
const AzureDevOpsRestAPIs = lazy(() => import('./screens/Content/Blog/Posts/DevOps/AzureDevOps/Index'));
const AzureDevOpsCreateProject = lazy(() => import('./screens/Content/Blog/Posts/DevOps/AzureDevOps/CreateProject'));
const AzureDevOpsFetchProjects = lazy(() => import('./screens/Content/Blog/Posts/DevOps/AzureDevOps/FetchProjects'));
const AzureDevOpsInviteUserToOrganisation = lazy(() => import('./screens/Content/Blog/Posts/DevOps/AzureDevOps/InviteUserToOrganisation'));
const AzureDevOpsCreateSecurityGroups = lazy(() => import('./screens/Content/Blog/Posts/DevOps/AzureDevOps/CreateSecurityGroup'));

const AppRouter = () => {
    return (
        <Switch>
            <Route exact path="/" >
                <BlogList />
            </Route>
            <Route exact path="/home" >
                <BlogList />
            </Route>

            {/*User*/}
            <Route path="/auth/external-login-callback" exact component={ExternalAuthenticationRedirect} />
            <Route path="/auth" exact component={AuthContainer} />
            <Route path="/request-password-reset-link" component={PasswordResetLink} />
            <Route path="/change-password" component={ChangePassword} />
            <Route path="/registration" exact component={Onboarding} />
            <Route path="/registration/payment-authorisation" exact component={PaymentAuthorisation} />
            <Route path="/registration/confirmation" exact component={Confirmation} />
            <Route path="/commsActivations" component={CommsActivations} />
            <ProtectedRoute exact path="/account-information/switch-plan" component={SwitchPlan} />
            <Route exact path="/account-information" component={AccountInformation} />

            {/*Content*/}
            <Route exact path="/resource/:name?" component={GatedResource} />
            <Route path={Routes.Blog.List} component={BlogList} />
            <Route path="/blog/what-is-pellerex" component={HowPellerexWorks} />
            <Route path="/blog/how-rekenix-works" component={HowRekenixWorks} />
            <Route path="/blog/url-rewrite-aspnet-core" component={UrlRewrite} />
            <Route path="/blog/react-redux-jwt-axios-asp-net-core-authentication-authorisation" component={Authentication} />
            <Route path="/blog/asp-net-core-api-setup" component={ApiSetup} />
            <Route path="/blog/asp-net-core-web-api-versioning" component={ApiVersioning} />
            <Route path="/blog/asp-net-5-web-api-configuration-management" component={ApiConfigurationManagement} />
            <Route path="/blog/asp-net-5-web-api-secret-management" component={ApiSecretManagement} />
            <Route path="/blog/asp-net-5-web-api-monitoring" component={ApiMonitoring} />
            <Route path="/blog/database-setup-for-aspnet-5-web-api-with-entity-framework-and-sql" component={ApiDatabase} />
            <Route path="/blog/asp-net-5-api-documentation-with-swagger" component={ApiDocumentation} />
            <Route path="/blog/asp-net-5-cors" component={ApiCors} />
            <Route path="/blog/asp-net-5-request-validation-with-fluent-validation" component={ApiRequestValidation} />
            <Route path="/blog/asp-net-5-global-exception-handling" component={ApiExceptionHandling} />
            <Route path="/blog/spa-with-asp-net-5-react-and-redux" component={SpaSetup} />
            <Route path="/blog/spa-styling-and-theme-management-with-scss" component={SpaStyling} />
            <Route path="/blog/spa-mobile-first-global-navigation" component={SpaGlobalNavigation} />
            <Route path="/blog/spa-layout" component={SpaGeneralLayout} />
            <Route path="/blog/spa-forms" component={SpaForms} />
            <Route path="/blog/spa-routing" component={SpaRouting} />
            <Route path="/blog/spa-configuration" component={SpaConfiguration} />
            <Route path="/blog/spa-api-call" component={SpaApiCall} />
            <Route path="/blog/spa-monitoring" component={SpaMonitoring} />
            <Route path="/blog/spa-notification" component={SpaNotification} />
            <Route path="/blog/spa-react-redux" component={SpaReactRedux} />
            <Route path="/blog/spa-google-maps-polygons" component={SpaGoogleMaps} />
            <Route path="/blog/spa-react-seo" component={SpaSeo} />
            <Route path="/blog/spa-aspnet-react-configuration" component={SpaAspNetShellConfigurations} />
            <Route path="/blog/spa-deploy-aspnet-with-react-using-azure-devops" component={SpaAzureDeploy} />
            <Route path="/blog/auth-identity-db-setup" component={AuthIdentityDbSetup} />
            <Route path="/blog/auth-email-sender" component={EmailSender} />
            <Route path="/blog/identity-sign-up" component={SignUp} />
            <Route path="/blog/asp-net-core-api-call-other-apis" component={ApiCall} />
            <Route path="/blog/identity-authenticated-hoc" component={AuthStatusCheck} />
            <Route path="/blog/identity-change-password" component={AuthChangePassword} />
            <Route path="/blog/identity-sign-in" component={SignIn} />
            <Route path="/blog/claim-based-access-management" component={TokenValidation} />
            <Route path="/blog/identity-token-refresh" component={TokenRefresh} />
            <Route path="/blog/payment-with-stripe" component={PaymentBlog} />
            <Route path="/blog/capture-card-details-with-react-and-stripe-elements" component={StripeCaptureCardDetails} />
            <Route path="/blog/process-one-time-payment-with-stripe" component={StripeOneTimePayment} />
            <Route path="/blog/process-subscriptions-using-stripe" component={StripeSubscriptions} />
            <Route path="/blog/structured-logging-with-serilog" component={StructuredLogging} />
            <Route path="/blog/google-auth-for-react-with-aspnet-identity" component={GoogleExternalAuthentication} />
            <Route path="/blog/microsoft-auth-for-react-with-aspnet-identity" component={MicrosoftExternalAuthentication} />
            <Route path="/blog/twitter-auth-for-react-with-aspnet-identity" component={TwitterExternalAuthentication} />
            <Route path="/blog/technologyleads-software-transformation-roadmap" component={SoftwareTransformationRoadmap} />
            <Route path="/blog/top-low-code-platform" component={LowCodePlatforms} />
            <Route path="/blog/containerise-aspnet-api" component={AspNetApiContainerisation} />
            <Route path="/blog/containerise-react-app-with-aspnet-and-azure-devops" component={AspNetAppContainerisation} />
            <Route path="/blog/aspnet-boiler-plate-solutions" component={BoilerplateSolutions} />
            <Route path="/blog/docker-compose-for-multi-container-apps" component={DockerCompose} />
            <Route path="/blog/run-aspnet-api-and-react-apps-on-minikube" component={PellerexOnMinikube} />
            <Route path="/blog/kubernetes-with-aspnet-react-and-azure-devops" component={PellerexOnKubernetes} />
            <Route path="/blog/event-driven-architecture-with-pellerex" component={EventDrivenArchitecture} />
            <Route path="/blog/commands-cheat-sheet" component={CommandsRepo} />
            <Route path="/blog/performance-and-cost-optimisation" component={PerformanceUplift} />
            <Route path="/blog/realtime-chat-with-signalR-dotnet-k8s" component={RealtimeMessaging} />
            <Route path="/blog/pellerex-ai-marketplace-for-data-scientists" component={AIMarketplace} />
            <Route exact path="/blog/foundation/azure-devops-rest-apis" component={AzureDevOpsRestAPIs} />
            <Route exact path="/blog/foundation/create-azure-devops-project" component={AzureDevOpsCreateProject} />
            <Route exact path="/blog/foundation/fetch-azure-devops-projects" component={AzureDevOpsFetchProjects} />
            <Route exact path="/blog/foundation/invite-users-to-azure-devops-organisation" component={AzureDevOpsInviteUserToOrganisation} />
            <Route exact path="/blog/foundation/create-azure-devops-security-group" component={AzureDevOpsCreateSecurityGroups} />
            <Route exact path={Routes.Foundation.Conversion.Newsletter} component={Conversion} />
            <Route exact path={Routes.Foundation.Conversion.ContactUs} component={Conversion} />
            {/*Products*/}
            <Route path="/roadmap" component={Roadmap} />

            {/*Others*/}
            <Route path="/policies" component={Policies} />
            <Route path="/contact-me" component={ContactUs} />
            <Route path="/work-with-me" component={WorkWithMe} />
            <Route path="/terms" component={Terms} />
            <Route component={NotFound} />
        </Switch>
    );
}

export default AppRouter;