import React, { Suspense, lazy, Component } from "react";
import styles from './Landing.module.scss';
import AppRouter from "../AppRouter";
const GlobalNavigation = lazy(() => import('../components/GlobalNavigation/GlobalNavigation'));
const Footer = lazy(() => import("../screens/Sections/Footer"));

class Landing extends Component {
    render() {
        return (
            <>
                <GlobalNavigation />
                <div className={styles.Container}>
                    <AppRouter />
                </div>
                <Footer />
            </>
        );
    }
}

export default Landing;