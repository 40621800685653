import { toast, Zoom } from "react-toastify";
import { uiNotificationSent } from '../actionUtils';

const toaster = (param) => (store) => (next) => (action) => {
    if (action.type === uiNotificationSent.type) {

        let messagePayload = action.payload;
        if(messagePayload.success){
            toast.success(action.payload.userFriendlyMessage, {
                position: toast.POSITION.TOP_CENTER,
                transition: Zoom
            });
        } else{
            toast.error(action.payload.userFriendlyMessage, {
                position: toast.POSITION.TOP_CENTER,
                transition: Zoom
            });
        }
    }
    return next(action);
};

export default toaster;