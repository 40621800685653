import { routeChangeBegan } from '../actionUtils';
import history from "../../../common/history";

const routeManager = (param) => (store) => (next) => (action) => {
    if (action.type !== routeChangeBegan.type) {
        return next(action);
    }
    const { intent, route } = action.payload;
    switch (intent) {
        case 'logout':
            history.push('/');
            break;
        case 'payment-authorisation':
            history.push('/registration/payment-authorisation');
            break;
        case 'payment-confirmation':
            history.push('/registration/confirmation');
            break;
        case 'external-payment-authentication':
            window.location = route
            break;
        default:
            break;
    }

    next(action);
};

export default routeManager;