export const LoanRepaymentMethods = {
    InterestOnly: 'InterestOnly',
    PrincipleAndInterst: 'PrincipleAndInterst'
}

export const Product = {
    Field: {
        AI: 'AI',
        Engineering: 'Engineering'
    },
    Names: {
        SoftwareFoundation: 'software-foundation'
    },
    Category: {
        Foundation: {
            Name: 'foundation',
            Type: {
                Identity: 'identity',
                Payment: 'payment',
                Full: 'full'
            }
        }
    }
}
export const MachineLearning = {
    Models: {
        SentimentApi: 'SENTIMENT-API',
        ObjectDetectionApi: 'OBJECT-DETECTION-API'
    }
}
export const LocalStorageKeys = {
    Parent: 'WebApp'
}

export const SessionStorageKeys = {
    Parent: 'WebApp'
}

export const Auth = {
    SignIn: 'Sign In',
    SignUp: 'Sign Up',
    InvalidCredentials: 'Email and/or password are incorrect.',
    PasswordRegEx: /^(?=.*[A-Z])(?=.*[\W])(?=.*[0-9])(?=.*[a-z]).{8,128}$/,
    PasswordFailMessage: "Passwords must have at least 8 characters, 1 lowercase, 1 upper case, 1 number, and 1 special character.",
    TokenHolderCookieName: 'pellerex-external-auth'
}

export const Registration = {
    Planning: 'ChoosePlan',
    Account: 'Account',
    Payment: 'Payment',
    Confirmed: 'Confirmed',
    Monthly: 'Monthly',
    Yearly: 'Yearly'
}

export const General = {
    PhoneValidationRegEx: /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
    DateFormat: 'YYYY-MM-DD',
    ErrorMessage: 'Oops, we didn\'t expect this, please try again later.',
    AlternativeErrorMessage: 'Oops, we didn\'t expect this, please send us an email at info@pellerex.com',
    WillBeInTouch: 'Got it, we will be in touch soon.',
    ErrorCodes: {
        SendEmailFailed: 'SendEmailFailed'
    },
    GUID: '00000000-0000-0000-0000-000000000000',
    Environment: {
        Dev: 'development',
        Prod: 'production'
    },
    PellerexEmailAddress: 'info@pellerex.com'
}

export const SEO = {
    Types: {
        Article: 'NewsArticle',
        Website: 'Website'
    }
}
export const Content = {
    Types: {
        Foundation: 'Foundation',
        DevOps: 'DevOps',
        Identity: 'Identity',
        Payment: 'Payment',
        Infrastructure: 'Infrastructure',
        ApiDevelopment: 'ApiDevelopment',
        WebDevelopment: 'WebDevelopment',
        MobileDevelopment: 'MobileDevelopment',
        Marketplace: {
            AI: 'AI'
        }
    }
}

export const Routes = {
    Blog: {
        WorkWithMe: '/work-with-me',
        ContactMe: '/contact-me',
        List: '/blog/list'
    },
    Foundation: {
        Root: 'https://foundation.pellerex.com',
        Messaging: 'https://foundation.pellerex.com/messaging',
        Identity: 'https://foundation.pellerex.com/identity',
        Terms: '/terms',
        Policies: '/policies',
        Conversion: {
            ContactUs: '/conversion/contact-us',
            Newsletter: '/conversion/newsletter'
        }
    }
}

export const Conversion = {
    Types: {
        BlogSubscription: {
            Key: 'blog-subscription',
            Header: 'Thanks. ',
            Message: 'From now on, I will notify you whenever I publish.'
        },
        ContactUs: {
            Key: 'contact-us',
            Header: 'Thanks.',
            Message: 'I will be in touch shortly.'
        }
    }
}

export const Resources = {
    EngineeringRoadmap: 'engineering-roadmap',
    Scoping: 'scoping'
}

export const Payment = {
    PaymentTypes: {
        Card: 'card'
    },
    PaymentMode: {
        OneTime: 'OneTime',
        Recurring: 'Recurring',
        Subscription: {
            Daily: 'Daily',
            Monthly: 'Monthly',
            Yearly: 'Yearly'
        }
    },
    PaymentStatus: {
        RequiresAction: 'RequiresAction',
        Succeeded: 'Succeeded',
        RequiresPaymentMethod: 'RequiresPaymentMethod',
        Failed: 'Failed'
    },
    SubscriptionStatus: {
        RequiresAction: 'RequiresAction',
        Succeeded: 'Succeeded',
        RequiresPaymentMethod: 'RequiresPaymentMethod',
        Failed: 'Failed'
    }
}
