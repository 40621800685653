import { createAction } from "@reduxjs/toolkit";

export const apiCallBegan = createAction("api/CallBegan");
export const apiCallSuccess = createAction("api/CallSuccess");
export const apiCallFailed = createAction("api/CallFailed");
export const accessTokenExpired = createAction("api/TokenExpired");
export const routeChangeBegan = createAction("route/ChangeBegan");
export const uiNotificationSent = createAction("uiNotification/Sent");

//Membership
export const customerSignupBegan = createAction("customer/signupBegan");
export const customerSigninBegan = createAction("customer/signinBegan");
export const processOneTimePaymentBegan = createAction('payment/processOneTimePaymentBegan');
export const processSubscriptionBegan = createAction('subscription/processSubscriptionBegan');
export const customerSignoutCompleted = createAction("rootReducer/signoutCompleted");
