import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../middleware/actionUtils";
import config from "../../config.json";
import _ from 'lodash';
import { getEnvironmentVariables } from '../../common/utils';

export const fetchCountries = () => {
    return apiCallBegan({
        baseUrl: getEnvironmentVariables().REACT_APP_LOCATION_API_URL,
        url: config.locations.fetchCountriesUrl,
        method: "get",
        data: {},
        onStart: dataOperationRequested.type,
        onSuccess: fetchCountriesSucceeded.type,
        onError: dataOperationFailed.type,
    });
};

export const fetchCountry = (countryCode) => {
    return apiCallBegan({
        baseUrl: getEnvironmentVariables().REACT_APP_LOCATION_API_URL,
        url: config.locations.fetchCountryUrl.replace('{countryCode}', countryCode),
        method: "get",
        data: {},
        onStart: dataOperationRequested.type,
        onSuccess: fetchCountrySucceeded.type,
        onError: dataOperationFailed.type,
    });
};

export const fetchSuburbs = (country, partialSuburbName) => {
    var url = config.locations.fetchSuburbsByName
        .replace('{country}', country)
        .replace('{suburbName}', partialSuburbName);

    return apiCallBegan({
        baseUrl: getEnvironmentVariables().REACT_APP_LOCATION_API_URL,
        url: url,
        method: "get",
        data: {},
        onStart: dataOperationRequested.type,
        onSuccess: fetchSuburbsSucceeded.type,
        onError: dataOperationFailed.type
    });
};

//
//Store Slice Creator
//

const slice = createSlice({
    name: "locations",
    initialState: {
        countries: [],
        loading: false,
        lastFetched: null,
    },
    reducers: {
        //Fetch States
        dataOperationRequested: (locations, action) => {
            locations.loading = true;
        },
        fetchCountriesSucceeded: (locations, action) => {
            locations.countries = action.payload;
            locations.lastFetched = Date.now();
            locations.loading = false;
        },
        fetchCountrySucceeded: (locations, action) => {
            locations.fetchedCountry = action.payload;
            locations.lastFetched = Date.now();
            locations.loading = false;
        },
        fetchSuburbsSucceeded: (locations, action) => {
            let transformedResult = _.reduce(
                action.payload,
                (result, e) => {
                    var existingCategory = _.find(result, o => o.name === e.state);
                    for (let postcode in e.postcodes) {
                        var newItem = {
                            'name': e.state,
                            'results': [{
                                'locality': e.locality,
                                'state': e.state,
                                'postcode': e.postcodes[postcode].postcode,
                                'postcodeSuburbId': e.postcodes[postcode].postcodeSuburbId
                            }]
                        };

                        if (existingCategory) {
                            existingCategory.results.push(newItem.results[0]);
                        } else {
                            result[e.state] = newItem;
                        };
                    }

                    return result;
                },
                {},
            );
            if (!locations.suburbs) {
                locations.suburbs = {};
            }

            locations.suburbs = { 'list': transformedResult };
            locations.lastFetched = Date.now();
            locations.loading = false;
        },
        dataOperationFailed: (locations, action) => {
            locations.loading = false;
        }
    },
});

export const {
    dataOperationRequested,
    fetchCountriesSucceeded,
    fetchCountrySucceeded,
    fetchSuburbsSucceeded,
    dataOperationFailed,
} = slice.actions;

export default slice.reducer;