import axios from "axios";
import { accessTokenExpired } from '../store/reducers/accountService';
import config from '../config.json';
import { isEmpty, getEnvironmentVariables } from "./utils";
const refreshUrl = '/v1/account/refresh-token';

let axiosInterceptor = null; // outer variable

export const getApi = (base, token, refreshToken, dispatch) => {
    const instance = axios.create({ baseURL: base });

    if (!!axiosInterceptor || axiosInterceptor === 0) {
        instance.interceptors.request.eject(axiosInterceptor);
    }

    let tokenHolder = token;

    // Add a request interceptor to handle headers
    axiosInterceptor = instance.interceptors.request.use(

        config => {
            //config.headers = headers;
            if (tokenHolder) {
                config.headers['Authorization'] = 'Bearer ' + tokenHolder;
            }
            config.headers['Content-Type'] = 'application/json;charset=UTF-8';
            config.headers['ocp-apim-subscription-key'] = getEnvironmentVariables().REACT_APP_API_PORTAL_SUBSCRIPTION;
            return config;
        },
        error => {
            Promise.reject(error)
        });
    //Add a response interceptor to handle refresh token behavior
    instance.interceptors.response.use((response) => {
        return response
    }, async function (error) {
        const originalRequest = error.config;
        if (isEmpty(error.response)) {
            return Promise.reject(error);
        }
        if ((error.response.status === 401 || error.response.status === 403) &&
            (originalRequest.url.includes(refreshUrl) ||
                originalRequest.url.includes(config.loginUrl) ||
                error.response.data.code === 'InvalidCredentials')) {
            //find a way for this: router.push('/login');
            //perhaps dispatch(loginExpired(response.data))? and then we push to router
            return Promise.reject(error);
        }

        if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
            originalRequest._retry = true;

            return await instance.request({
                baseURL: getEnvironmentVariables().REACT_APP_IDENTITY_API_URL,
                url: refreshUrl,
                method: 'POST',
                data: {
                    'accessToken': tokenHolder,
                    'refreshToken': refreshToken
                },
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': 'Bearer ' + tokenHolder
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        //dispatch to update state with new tokens
                        if (dispatch) {
                        dispatch({
                            type: accessTokenExpired.type,
                            payload: response.data.user
                        });
                        }
                        tokenHolder = response.data.user.accessToken;
                        return instance(originalRequest);
                    }
                })
        }
        return Promise.reject(error);
    });

    return instance;
}