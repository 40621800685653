import { getApi } from '../../../common/axiosExtensions';
import { apiCallBegan, apiCallSuccess, routeChangeBegan, uiNotificationSent } from "../actionUtils";
import { General } from '../../../common/constants';
import { isEmpty } from '../../../common/utils';
import config from "../../../config.json";
const refreshUrl = '/v1/account/refresh-token';

const api = ({ dispatch, getState }) => (next) => async (action) => {
    if (action.type !== apiCallBegan.type) {
        return next(action);
    }

    const {
        baseUrl,
        url,
        method,
        data,
        token,
        refreshToken,
        headers,
        onSuccess,
        onStart,
        onError,
        uiSuccessMessage } = action.payload;

    try {
        if (onStart) {
            dispatch({
                type: onStart,
            });
        }

        next(action);

        const response = await getApi(baseUrl, token, refreshToken, dispatch).request({
            url,
            headers,
            method,
            data
        });

        //General
        dispatch(apiCallSuccess(response.data));

        if (url === config.accounts.logoutUrl) {
            dispatch(routeChangeBegan({ 'intent': 'logout' }));
        }

        if (!isEmpty(uiSuccessMessage)) {
            let messagePayload = {
                success: true,
                userFriendlyMessage: uiSuccessMessage
            }

            dispatch(uiNotificationSent(messagePayload));
        }
        //Specific
        if (onSuccess) {
            dispatch({
                type: onSuccess,
                payload: response.data,
            });
        }
    } catch (error) {
        let messagePayload = {
            statusCode: error.response?.status || '',
            message: error.message,
            errorCode: error.response?.data?.code || '',
            userFriendlyMessage: error.response?.data?.description || General.ErrorMessage
        }

        //logService.log(JSON.stringify(errorPayload));
        //General
        if (url !== refreshUrl) {
            dispatch(uiNotificationSent(messagePayload));
        }
        //Specific
        if (onError) {
            dispatch({
                type: onError,
                payload: messagePayload,
            });
        }
    }
};
export default api;