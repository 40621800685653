import { getApi } from '../../../common/axiosExtensions';
import {
    uiNotificationSent,
    customerSignupBegan,
    customerSigninBegan
} from "../actionUtils";
import { General } from '../../../common/constants';
import config from "../../../config.json";
import { isEmpty, getEnvironmentVariables } from '../../../common/utils';
const refreshUrl = '/v1/account/refresh-token';

export const customerSignupApiOrchestrator = ({ dispatch, getState }) => (next) => async (action) => {
    if (action.type !== customerSignupBegan.type) {
        return next(action);
    }
    const {
        data,
        onSuccess,
        onStart,
        onError,
        uiSuccessMessage } = action.payload;

    try {
        if (onStart) {
            dispatch({
                type: onStart,
            });
        }

        next(action);

        var result = {};

        //sign up
        const identityResponse = await getApi(
            getEnvironmentVariables().REACT_APP_IDENTITY_API_URL,
            'noTokenNeeded',
            'noRefreshTokenNeeded',
            dispatch)
            .request({
                url: config.accounts.signupUrl,
                headers: {},
                method: 'POST',
                data: data,
            });

        if (!isEmpty(identityResponse.data.user.email)) {
            
            result = {
                user: identityResponse.data.user,
            }

            if (!isEmpty(uiSuccessMessage)) {
                let messagePayload = {
                    success: true,
                    userFriendlyMessage: uiSuccessMessage
                }

                dispatch(uiNotificationSent(messagePayload));
            }

            //Specific
            if (onSuccess) {
                dispatch({
                    type: onSuccess,
                    payload: result
                });
            }
        }
    } catch (error) {
        var errorPayload = {
            statusCode: error.response?.status || '',
            message: error.message,
            errorCode: error.response?.data?.code || '',
            userFriendlyMessage: error.response?.data?.description || General.ErrorMessage
        }

        //General
        if (!error.response || (error.response && error.response.config.url !== refreshUrl)) {
            dispatch(uiNotificationSent(errorPayload));
        }

        //Specific
        if (onError) {
            dispatch({
                type: onError,
                payload: errorPayload,
            });
        }
    }
};

export const customerSigninApiOrchestrator = ({ dispatch, getState }) => (next) => async (action) => {
    if (action.type !== customerSigninBegan.type) {
        return next(action);
    }
    const {
        data,
        onSuccess,
        onStart,
        onError,
        uiSuccessMessage } = action.payload;

    try {
        if (onStart) {
            dispatch({
                type: onStart,
            });
        }

        next(action);

        var result = {};
        //sign in
        const identityResponse = await getApi(
            getEnvironmentVariables().REACT_APP_IDENTITY_API_URL,
            'noTokenNeeded',
            'noRefreshTokenNeeded',
            dispatch).request({
                url: config.accounts.loginUrl,
                headers: {},
                method: 'POST',
                data: data
            });

        if (!isEmpty(identityResponse.data.user.email)) {
            //get customer subscription
            const customerResponse = await getApi(
                getEnvironmentVariables().REACT_APP_SUBSCRIPTION_API_URL,
                identityResponse.data.user.accessToken,
                identityResponse.data.user.refreshToken,
                dispatch)
                .request({
                    url: config.accounts.getCurrentUserActiveSubscriptionUrl,
                    headers: {},
                    method: 'GET'
                });

            result = {
                user: identityResponse.data.user,
                subscription: customerResponse.data.subscription,
                providerCustomer: customerResponse.data.providerCustomer,
            }

            if (!isEmpty(uiSuccessMessage)) {
                let messagePayload = {
                    success: true,
                    userFriendlyMessage: uiSuccessMessage
                }

                dispatch(uiNotificationSent(messagePayload));
            }

            //Specific
            if (onSuccess) {
                dispatch({
                    type: onSuccess,
                    payload: result
                });
            }
        }
    } catch (error) {
        var messagePayload = {
            statusCode: error.response?.status || '',
            message: error.message,
            errorCode: error.response?.data?.code || '',
            userFriendlyMessage: error.response?.data?.description || General.ErrorMessage
        }

        //General
        if (!error.response || (error.response && error.response.config.url !== refreshUrl)) {
            dispatch(uiNotificationSent(messagePayload));
        }

        //Specific
        if (onError) {
            dispatch({
                type: onError,
                payload: messagePayload,
            });
        }
    }
};