import { applyMiddleware, createStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import logger from "./middleware/core/logger";
import api from "./middleware/core/api";
import toaster from "./middleware/core/toaster";
import { composeWithDevTools } from 'redux-devtools-extension'
import storage from 'redux-persist/lib/storage/session' // drop session to go back localstorage
import { getEnvironmentVariables } from '../common/utils';
import { persistStore, persistReducer } from 'redux-persist'
import routeManager from "./middleware/core/routeManager";
import {
    oneTimePaymentOrchestrator,
    recurringPaymentOrchestrator
} from "./middleware/apiOrchestrators/paymentApiOrchestrator";
import {
    customerSignupApiOrchestrator,
    customerSigninApiOrchestrator
} from "./middleware/apiOrchestrators/customerApiOrchestrator";
// getDefaultMiddleware automatically registers thunk, the middleware used to dispach functions
let initialState = {};

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = applyMiddleware(
    oneTimePaymentOrchestrator,
    recurringPaymentOrchestrator,
    customerSignupApiOrchestrator,
    customerSigninApiOrchestrator,
    ...getDefaultMiddleware({
        serializableCheck: false
    }),
    logger({ destination: "console" }),
    toaster(),
    api,
    routeManager());

const devTools =
    getEnvironmentVariables().NODE_ENV === "production"
        ? middleware
        : composeWithDevTools(middleware);

export default () => {
    let store = createStore(
        persistedReducer,
        initialState,
        devTools
    );

    let persistor = persistStore(store)
    return { store, persistor }
}