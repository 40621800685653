import { createSlice } from "@reduxjs/toolkit";
import {
    apiCallBegan,
    processOneTimePaymentBegan,
    processSubscriptionBegan,
    customerSignupBegan,
    customerSigninBegan,
    customerSignoutCompleted
} from "../middleware/actionUtils";
import config from "../../config.json";
import { isEmpty, getEnvironmentVariables } from '../../common/utils';
import { Registration } from '../../common/constants';

//Products
export const fetchProducts = () => {
    var action = apiCallBegan({
        baseUrl: getEnvironmentVariables().REACT_APP_SUBSCRIPTION_API_URL,
        url: config.accounts.fetchProductsUrl,
        method: "get",
        data: {},
        onStart: dataOperationRequested.type,
        onSuccess: fetchProductsSucceeded.type,
        onError: dataOperationFailed.type,
    });

    return action;
}

//Sign In/Up
export const getUserActiveSubscription = (accessToken, refreshToken) => {
    var action = apiCallBegan({
        baseUrl: getEnvironmentVariables().REACT_APP_SUBSCRIPTION_API_URL,
        url: config.accounts.getCurrentUserActiveSubscriptionUrl,
        method: "get",
        token: accessToken,
        refreshToken: refreshToken,
        data: {},
        onStart: dataOperationRequested.type,
        onSuccess: getCurrentUserActiveSubscriptionSucceeded.type,
        onError: dataOperationFailed.type,
    });

    return action;
}
export const updateProfile = (profile, accessToken, refreshToken) => {
    var action = apiCallBegan({
        baseUrl: getEnvironmentVariables().REACT_APP_IDENTITY_API_URL,
        url: config.accounts.updateProfileUrl,
        method: "put",
        token: accessToken,
        refreshToken: refreshToken,
        data: profile,
        onStart: dataOperationRequested.type,
        onSuccess: updateProfileSucceeded.type,
        onError: dataOperationFailed.type,
    });

    return action;
};
export const getUser = (email, accessToken, refreshToken) => {
    var action = apiCallBegan({
        baseUrl: getEnvironmentVariables().REACT_APP_IDENTITY_API_URL,
        url: config.accounts.getUserUrl.replace('{email}', email),
        method: "get",
        token: accessToken,
        refreshToken: refreshToken,
        data: { email },
        onStart: dataOperationRequested.type,
        onSuccess: getUserSucceeded.type,
        onError: dataOperationFailed.type,
    });

    return action;
};
export const login = ({ email, password, captchaToken }) => {
    var action = customerSigninBegan({
        baseUrl: getEnvironmentVariables().REACT_APP_IDENTITY_API_URL,
        url: config.accounts.loginUrl,
        method: "post",
        data: { email, password, captchaToken },
        onStart: dataOperationRequested.type,
        onSuccess: loginSucceeded.type,
        onError: dataOperationFailed.type,
    });

    return action;
};
export const externalLogin = (tokenDetails) => {
    return {
        type: "accountReducer/externalLoginSucceeded",
        payload: tokenDetails
    };
};
export const signup = (newProfile) => {
    return customerSignupBegan({
        data: newProfile,
        onStart: dataOperationRequested.type,
        onSuccess: signupSucceeded.type,
        onError: dataOperationFailed.type,
    });
};
export const logout = (token, refreshToken) => {
    return apiCallBegan({
        baseUrl: getEnvironmentVariables().REACT_APP_IDENTITY_API_URL,
        url: config.accounts.logoutUrl,
        method: "post",
        headers: { 'Authorization': `Bearer ${token}` },
        token,
        refreshToken,
        onStart: dataOperationRequested.type,
        onSuccess: customerSignoutCompleted.type,
        onError: customerSignoutCompleted.type
    });
};
export const activateEmail = (email, activationToken) => {
    let url = `${config.accounts.emailActivationResponseUrl}?email=${email}&activationToken=${activationToken}`;
    return apiCallBegan({
        baseUrl: getEnvironmentVariables().REACT_APP_IDENTITY_API_URL,
        url: url,
        method: "post",
        onStart: dataOperationRequested.type,
        onSuccess: emailActivationSucceeded.type,
        onError: emailActivationFailed.type,
    });
};
export const sendResetLink = (email) => {
    let url = `${config.accounts.sendPasswordResetLinkUrl}?email=${email}`;
    return apiCallBegan({
        baseUrl: getEnvironmentVariables().REACT_APP_IDENTITY_API_URL,
        url: url,
        method: "post",
        onStart: dataOperationRequested.type,
        onSuccess: passwordResetLinkSent.type,
        onError: emailActivationFailed.type,
    });
};
export const resetPassword = (email, password, resetPasswordToken) => {
    return apiCallBegan({
        baseUrl: getEnvironmentVariables().REACT_APP_IDENTITY_API_URL,
        url: config.accounts.resetPasswordUrl,
        method: "post",
        data: {
            email,
            password,
            resetPasswordToken
        },
        onStart: resetPasswordRequested.type,
        onSuccess: resetPasswordSucceeded.type,
        onError: resetPasswordFailed.type
    });
};
export const resendPasswordResetEmail = () => {
    return {
        type: "accountReducer/resendPasswordResetEmailRequested"
    };
};

//Payment & Subscription
export const processOneTimePayment = (paymentInfo) => {
    return processOneTimePaymentBegan({
        data: paymentInfo,
        onStart: dataOperationRequested.type,
        onThreeDAuthenticationStart: threeDAuthenticationStarted.type,
        onThreeDAuthenticationSuccess: threeDAuthenticationSucceeded.type,
        onThreeDAuthenticationError: threeDAuthenticationFailed.type,
        onSuccess: processOneTimePaymentSucceeded.type,
        onError: dataOperationFailed.type
    });
};
export const checkPaymentStatus = ({ intentId, accessToken, refreshToken }) => {

};
export const processSubscription = (paymentInfo) => {
    return processSubscriptionBegan({
        data: paymentInfo,
        method: "post",
        onStart: dataOperationRequested.type,
        onThreeDAuthenticationStart: threeDAuthenticationStarted.type,
        onThreeDAuthenticationSuccess: threeDAuthenticationSucceeded.type,
        onThreeDAuthenticationError: threeDAuthenticationFailed.type,
        onSuccess: processSubscriptionSucceeded.type,
        onError: dataOperationFailed.type
    });
};
export const checkIntentStatus = ({ intentId, accessToken, refreshToken }) => {
    let url = ''

    if (intentId?.startsWith('pi')) {
        url = config.payments.checkPaymentStatusUrl.replace('{id}', intentId);


    } else if (intentId?.startsWith('seti')) {
        url = config.subscriptions.checkSetupIntentStatusUrl.replace('{id}', intentId);

    } else {
        return {};
    }

    return apiCallBegan({
        baseUrl: getEnvironmentVariables().REACT_APP_SUBSCRIPTION_API_URL,
        url: url,
        token: accessToken,
        refreshToken: refreshToken,
        data: {},
        onStart: intentStatusCheckRequested.type,
        onSuccess: intentStatusCheckSucceeded.type,
        onError: dataOperationFailed.type
    });
};
export const saveNewPlan = (chosenPlan) => {
    return {
        type: "accountReducer/saveNewPlanRequested",
        payload: {
            chosenPlan
        }
    };
};
export const switchPlan = (priceId, token, refreshToken) => {
    return apiCallBegan({
        baseUrl: getEnvironmentVariables().REACT_APP_SUBSCRIPTION_API_URL,
        url: config.accounts.switchPlanUrl.replace('{priceId}', priceId),
        method: "PUT",
        token: token,
        refreshToken: refreshToken,
        onStart: dataOperationRequested.type,
        onSuccess: switchPlanSuccceeded.type,
        onError: dataOperationFailed.type,
        uiSuccessMessage: 'Your plan was updated successfully.'
    });
}

//Navigation
export const goBackToStep = (step) => {
    return {
        type: "accountReducer/stepBackRequested",
        payload: {
            registrationStep: step
        }
    };
}

//Reducers

const slice = createSlice({
    name: "accountReducer",
    initialState: {
        membership: {
            user: {},
            providerCustomer: {},
            subscription: {}
        },
        subscriptions: {
            products: []
        },
        onboarding: {
            payment: {},
            subscription: {}
        },
        loading: false
    },
    reducers: {
        dataOperationRequested: (accounts, action) => {
            accounts.loading = true;
        },
        //Login
        loginSucceeded: (accounts, action) => {
            accounts.lastFetched = Date.now();
            accounts.loading = false;
            accounts.membership = { ...accounts.membership, ...action.payload }
        },
        externalLoginSucceeded: (accounts, action) => {
            accounts.lastFetched = Date.now();
            accounts.loading = false;
            accounts.membership = { ...accounts.membership, ...action.payload }
        },
        signupSucceeded: (accounts, action) => {
            accounts.lastFetched = Date.now();
            accounts.loading = false;
            accounts.onboarding.registrationStep = Registration.Payment;
            accounts.membership = { ...accounts.membership, ...action.payload };
        },
        //Profile
        fetchProductsSucceeded: (accounts, action) => {
            accounts.loading = false;
            accounts.subscriptions.products = action.payload;
        },
        emailActivationSucceeded: (accounts, action) => {
            accounts.lastFetched = Date.now();
            accounts.loading = false;
            if (isEmpty(accounts.membership)) {
                accounts.membership = {
                    user: {},
                    providerCustomer: {},
                    subscription: {}
                };
            }
            accounts.membership.user.emailActivated = true;
        },
        emailActivationFailed: (accounts, action) => {
            accounts.lastFetched = Date.now();
            accounts.loading = false;
            if (isEmpty(accounts.membership)) {
                accounts.membership = {
                    user: {},
                    providerCustomer: {},
                    subscription: {}
                };
            }
            accounts.membership.user.emailActivated = false;
        },
        passwordResetLinkRequested: (accounts, action) => {
            accounts.lastFetched = Date.now();
            accounts.loading = true;
            accounts.membership.user.passwordResetLinkSent = false;
        },
        passwordResetLinkSent: (accounts, action) => {
            accounts.lastFetched = Date.now();
            accounts.loading = false;
            accounts.membership.user.passwordResetLinkSent = true;
        },
        resendPasswordResetEmailRequested: (accounts, action) => {
            accounts.membership.user.passwordResetLinkSent = false;
        },
        resetPasswordRequested: (accounts, action) => {
            accounts.lastFetched = Date.now();
            accounts.loading = true;
            accounts.membership.user.changePasswordSucceeded = false;
        },
        resetPasswordSucceeded: (accounts, action) => {
            accounts.lastFetched = Date.now();
            accounts.loading = false;
            accounts.membership.user.changePasswordSucceeded = true;
        },
        resetPasswordFailed: (accounts, action) => {
            accounts.lastFetched = Date.now();
            accounts.loading = false;
        },
        accessTokenExpired: (accounts, action) => {
            accounts.lastFetched = Date.now();
            accounts.membership.user.accessToken = action.payload.accessToken;
            accounts.membership.user.refreshToken = action.payload.refreshToken;
        },
        getUserSucceeded: (accounts, action) => {
            accounts.membership = {
                ...accounts.membership,
                user: action.payload.user
            };
            accounts.loading = false;
        },
        updateProfileSucceeded: (accounts, action) => {
            accounts.membership.user = { ...accounts.membership.user, ...action.payload };
            accounts.onboarding.registrationStep = Registration.Payment;

            accounts.loading = false;
        },
        getCurrentUserActiveSubscriptionSucceeded: (accounts, action) => {
            accounts.membership = {
                ...accounts.membership,
                customerAccount: action.payload.customerAccount
            };
            accounts.loading = false;
        },
        //On boarding
        saveNewPlanRequested: (accounts, action) => {
            accounts.onboarding = {
                ...accounts.onboarding, ...{
                    chosenPlan: action.payload.chosenPlan,
                    registrationStep: Registration.Account
                }
            }
        },
        //Payment
        threeDAuthenticationStarted: (accounts, action) => {
            accounts.loading = false;
            accounts.onboarding.registrationStep = Registration.Planning;
            accounts.onboarding.payment.status = action.payload.status;
            accounts.onboarding.payment.intentId = action.payload.intentId;
            accounts.onboarding.payment.authenticatorUrl = action.payload.nextAction?.authenticatorUrl;
            accounts.membership.providerCustomer = action.payload.member.providerCustomer;
        },
        threeDAuthenticationSucceeded: (accounts, action) => {
            accounts.loading = false;
            accounts.onboarding.registrationStep = Registration.Planning;
        },
        threeDAuthenticationFailed: (accounts, action) => {
            accounts.loading = false;
            accounts.onboarding.registrationStep = Registration.Planning;
        },
        processOneTimePaymentSucceeded: (accounts, action) => {
            accounts.loading = false;
            accounts.onboarding.registrationStep = Registration.Planning;
            accounts.onboarding.payment.status = action.payload.status;
            accounts.onboarding.payment.intentId = action.payload.intentId;
            accounts.onboarding.payment.authenticatorUrl = action.payload.nextAction?.authenticatorUrl;
            accounts.membership.providerCustomer = action.payload.member.providerCustomer;
        },
        //Subscription
        processSubscriptionSucceeded: (accounts, action) => {
            accounts.loading = false;
            accounts.onboarding.registrationStep = Registration.Planning;
            accounts.onboarding.payment.status = action.payload.status;
            accounts.onboarding.payment.intentId = action.payload.intentId;
            accounts.membership.subscription = action.payload.subscription;
            accounts.membership.providerCustomer = action.payload.member.providerCustomer;
        },
        intentStatusCheckRequested: (accounts, action) => {
            accounts.loading = false;
            accounts.onboarding.payment.status = '';
        },
        intentStatusCheckSucceeded: (accounts, action) => {
            accounts.loading = false
            accounts.onboarding.payment.status = action.payload.status;
        },
        switchPlanSuccceeded: (accounts, action) => {
            accounts.loading = false;
            accounts.membership.subscription = action.payload;
        },
        dataOperationFailed: (accounts, action) => {
            accounts.loading = false;
        },
        //Navigation
        stepBackRequested: (accounts, action) => {
            accounts.onboarding = { ...accounts.onboarding, ...{ registrationStep: action.payload.registrationStep } }
        }
    }
});
export const {
    dataOperationRequested,
    //General Membership
    loginSucceeded,
    signupSucceeded,
    signupWithAPlanSucceeded,
    emailActivationSucceeded,
    emailActivationFailed,
    accessTokenExpired,
    passwordResetLinkRequested,
    passwordResetLinkSent,
    resendPasswordResetEmailRequested,
    resetPasswordRequested,
    resetPasswordSucceeded,
    resetPasswordFailed,
    //Onboarding
    saveNewPlanRequested,
    createCardPaymentMethodRequested,
    createCardPaymentMethodSucceeded,
    createCardPaymentMethodFailed,
    createCustomerRequested,
    createCustomerSucceeded,
    createCustomerFailed,
    //Subscription
    fetchProductsSucceeded,
    processSubscriptionSucceeded,
    createSubscriptionSucceeded,
    switchPlanSuccceeded,
    intentStatusCheckRequested,
    intentStatusCheckSucceeded,
    //Payment
    threeDAuthenticationStarted,
    threeDAuthenticationSucceeded,
    threeDAuthenticationFailed,
    processOneTimePaymentSucceeded,
    //4.0 Navigation
    stepBackRequested,
    //5.0 Profile
    getUserSucceeded,
    updateProfileSucceeded,
    getCurrentUserActiveSubscriptionSucceeded,
    dataOperationFailed,
} = slice.actions;
export default slice.reducer;