import { getAppInsights } from './telemetryService';
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { getEnvironment } from '../../common/utils';
import { General } from '../../common/constants';

const info = (message) => {
    let obj = {
        message: message,
        severityLevel: SeverityLevel.Information
    };

    if (getEnvironment() === General.Environment.Dev) {
        console.log(obj);
        return;
    }

    let insights = getAppInsights();
    if (insights) {
        insights.trackTrace(obj);
    }
}

const warning = (message) => {

    let obj = {
        message: message,
        severityLevel: SeverityLevel.Warning
    };

    if (getEnvironment() === General.Environment.Dev) {
        console.log(obj);
        return;
    }

    let insights = getAppInsights();
    if (insights) {
        insights.trackTrace(obj);
    }
}

const error = (error) => {
    let obj = {
        message: error,
        severityLevel: SeverityLevel.Error
    };

    if (getEnvironment() === General.Environment.Dev) {
        console.log(obj);
        return;
    }

    let insights = getAppInsights();
    if (insights) {
        insights.trackException(obj);
    }
}

const event = (name, properties) => {

    let obj = {
        name,
        properties
    };

    if (getEnvironment() === General.Environment.Dev) {
        console.log(obj);
        return;
    }

    let insights = getAppInsights();
    if (insights) {
        insights.trackEvent(obj);
    }
}

export default {
    info,
    warning,
    error,
    event
};