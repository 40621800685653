import React from "react";
// The import below should be updated to match your Router component
import Routes from "../../AppRouter";
import DynamicSitemap from "react-dynamic-sitemap";

const Sitemap = (props) => {
    return (
        <DynamicSitemap routes={Routes} prettify={true}  {...props} />
    );
}

export default Sitemap;