import React, { Suspense, Component, lazy } from "react";
import returnStoreAndPersistor from "./store/store";
import { Provider } from "react-redux";
import { Switch, Route, Router } from "react-router-dom";
import history from "./common/history";
import { ToastContainer } from "react-toastify";
import Spinner from "./components/common/Spinner";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from 'redux-persist/integration/react'
import Landing from "./containers/Landing";
import styles from './App.module.scss';
//Instrumentation (below two)
import { getAppInsights } from './services/monitoring/telemetryService';
import TelemetryProvider from './services/monitoring/telemtryProvider';
import Sitemap from './components/common/Sitemap';
import { getEnvironmentVariables } from './common/utils';
import { Chat } from "./common/conversion/chat";
const PaymentAuthorisationRedirect = lazy(() => import("./screens/Registration/Payment/PaymentAuthorisationRedirect"));

const { store, persistor } = returnStoreAndPersistor();
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    appInsights = null;

    render() {
        return (
            <div className={styles.App} >
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Router history={history}>
                            <TelemetryProvider instrumentationKey={getEnvironmentVariables().REACT_APP_INSTRUMENTATION_KEY} after={() => { this.appInsights = getAppInsights() }}>
                                <Chat />
                                <ToastContainer />
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Switch>
                                        <Route exact path="/sitemap2" component={Sitemap} />
                                        <Route exact path="/registration/payment-authorisation-redirect" component={PaymentAuthorisationRedirect} />
                                        <Route path="/" >
                                            <Landing />
                                        </Route>
                                    </Switch>
                                </Suspense>
                            </TelemetryProvider>
                            <Spinner />
                        </Router>
                    </PersistGate>
                </Provider>
            </div>
        );
    }
}

export default App;