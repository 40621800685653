import moment from "moment";
import numeral from 'numeral';
import { v4 as uuidv4 } from 'uuid';
import bcrypt from 'bcryptjs';
import { Product, SessionStorageKeys } from "./constants";
import { Routes } from "./constants";
import config from "../config.json";

export const readFromCache = (lastFetched) => {
    const diffInSeconds = moment().diff(moment(lastFetched), "seconds");
    //60 to come from configs
    if (diffInSeconds < 1) {
        return true;
    }
    return false;
};

export const formatNumber = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatCurrency = (number, format = '($0.00 a)') => {
    return numeral(number).format(format);
}

export const shortenIncome = (x) => {
    return (Math.floor(x / 1000)).toString() + 'k';
}

export const toPascalCase = (x) => {
    return x.replace(/\w+/g,
        function (w) { return w[0].toUpperCase() + w.slice(1).toLowerCase(); });
}

export const isEqualTo = (a, b) => {
    return a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0;
}

export const getHashcode = (initiator) => {
    var hash = 0;
    for (var i = 0; i < initiator.length; i++) {
        hash = initiator.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}


/// Calculates the difference between two dates in UTC Iso format.
/// It matters for the format to follow 'yyyy-MM-ddThh:mm:ss.sssZ' otherwise complications arises
/// For the record, to get the datetime.now in Iso format use: (new Date()).toISOString()
export const getTimeDifference = ({ one, two, brief = false }) => {

    var dateTime1 = new Date(one).getTime();
    var dateTime2 = new Date(two).getTime();

    if (isNaN(dateTime1) || isNaN(dateTime2)) {
        return "";
    }

    if (dateTime1 < dateTime2) {
        var miliSecDiff = dateTime2 - dateTime1;
    } else {
        var miliSecDiff = dateTime1 - dateTime2;
    }

    var days = Math.floor(miliSecDiff / 1000 / 60 / (60 * 24));

    var date_diff = new Date(miliSecDiff);

    let hours = date_diff.getUTCHours();
    let minutes = date_diff.getUTCMinutes();
    let seconds = date_diff.getUTCSeconds();
    //Concatenate the outcome

    let result = '';

    if (days > 0) {
        result += days + (days === 1 ? " day " : " days");

        if (brief) {
            return result;
        }
    }

    if (hours > 0) {
        result += hours + (hours === 1 ? " hour " : " hours ");

        if (brief) {
            return result;
        }
    }

    if (minutes > 0) {
        result += minutes + (minutes === 1 ? " minute" : " minutes ");

        if (brief) {
            return result;
        }
    }

    if (seconds > 0) {
        result += seconds + (seconds === 1 ? " second" : " seconds");
    }

    return result;
}

//Local Storage
export const retrieveLocalStorage = (key) => {
    let jsonStorage = {};

    let retriviedLocalStorage = localStorage.getItem(key);

    if (retriviedLocalStorage !== 'undefined' && retriviedLocalStorage != null) {
        jsonStorage = JSON.parse(retriviedLocalStorage);
    }
    return jsonStorage;
}

export const saveLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}

export const retrieveSessionStorage = (key) => {
    let jsonStorage = {};

    let retriviedLocalStorage = sessionStorage.getItem(key);

    if (retriviedLocalStorage !== 'undefined' && retriviedLocalStorage != null) {
        jsonStorage = JSON.parse(retriviedLocalStorage);
    }
    return jsonStorage;
}

export const saveSessionsStorage = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
}

//Type Functions

export const getPublicImageUrl = (fileName) => {
    return (getEnvironmentVariables().PUBLIC_URL + '/img/' + fileName);
}

export const removeTrailingCharacters = (value) => {
    if (value) {
        return value.replace(/,\s*$/, "");
    }

    return '';
}

//Random Functions

export const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * max) + min;
}

export const getRandomId = () => {
    return uuidv4();
}

export const getEmptyObject = (type) => {
    switch (type) {
        case 'Guid':
            return '00000000-0000-0000-0000-000000000000';
    }

    return null;
}

export const getRandomColor = (randomString) => {
    var hash = 0;
    for (var i = 0; i < randomString.length; i++) {
        hash = randomString.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

//Cryptographyc

export const generateHash = (text) => {
    var salt = bcrypt.genSaltSync(10);
    var hash = bcrypt.hashSync(text, salt);
    return hash;
}

//Objects
export const isEmpty = (obj) => {

    if (obj === getEmptyObject('Guid')) {
        return true;
    }

    let result = !obj || obj == null || Object.keys(obj).length === 0;
    return result;
}

export const isEqualCaseInsensitive = (obj, target) => {
    return obj.localeCompare(target, undefined, { sensitivity: 'accent' }) === 0;
}

//Url
export const getRedirectUrl = () => {
    var storage = retrieveSessionStorage(SessionStorageKeys.Parent);
    var url = storage.redirectUri;
    storage.redirectUri = '';
    saveSessionsStorage(SessionStorageKeys.Parent, storage);
    return (url || '/');
}

export const setRedirectUrl = (route) => {
    var storage = retrieveSessionStorage(SessionStorageKeys.Parent);
    storage.redirectUri = route;
    saveSessionsStorage(SessionStorageKeys.Parent, storage);
}

//Environments
export const getEnvironment = () => {
    return getEnvironmentVariables().REACT_APP_Environment;
}

export const getEnvironmentVariables = () => {
    if (!isEmpty(window._env_)) {
        return window._env_;
    }
    return process.env;
}

export const getSwagerDashboardUrl = (model) => {

    return `${getApiEndpoint(model)}/swagger`;
}

export const getApiEndpoint = (model) => {

    switch (model) {
        case Routes.Marketplace.Models.ProductReviewSentiment:
            return `${getEnvironmentVariables().REACT_APP_ML_SENTIMENT_API_URL}`;
        case Routes.Marketplace.Models.TextModerationApi:
            return `${getEnvironmentVariables().REACT_APP_ML_SENTIMENT_API_URL}`;
        case Routes.Marketplace.Models.ObjectDetectionApi:
            return `${getEnvironmentVariables().REACT_APP_ML_OBJECTDETECTION_API_URL}`;
        case Routes.Marketplace.Models.LanguageProcessingApi:
            return `${getEnvironmentVariables().REACT_APP_ML_LANGUAGEPROCESSING_API_URL}`;
        default:
            return Product.Names.NotConfigured;
    }
}

//Others
export const getLogo = (name) => {
    let url = '';
    switch (name) {
        case 'Google':
            url = 'https://technologyleadsstorage.blob.core.windows.net/public/ecosystem/web/others/logos/pellerex-google-logo.png';
            break;
        case 'Microsoft':
            url = 'https://technologyleadsstorage.blob.core.windows.net/public/ecosystem/web/home/technologyleads-microsoft-logo.png';
            break;
        case 'Twitter':
            url = 'https://technologyleadsstorage.blob.core.windows.net/public/ecosystem/web/home/technologyleads-twitter-logo.png';
            break;
        default:
            url = '';
            break;
    }
    return url;
}

export const getProfilePhoto = (userId) => {
    let baseUrl = getEnvironmentVariables().REACT_APP_IDENTITY_API_URL;
    let url = config.accounts.fetchProfilePhotoUrl.replace('{userId}', userId);
    const profilePhotoUrl = `${baseUrl}/${url}`;
    return profilePhotoUrl;
}

export const scrollToTop = () => {
    document.body.scrollTo(0, 0);
}
